import React, { useEffect, lazy } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Choose2 from "../../Component2/HeroSection2/Choose2";
import Experience2 from "../../Component2/HeroSection2/Experience2";
import ClientContainer2 from "../../Component2/Client2/ClientContainer2";
import FooterCart2 from "../../Component2/Client2/FooterCart2";
import TeamsContainer2 from "../../Component2/Our Team2/TeamsContainer2";
const FastTrack2 = lazy(() =>
  import("../../Component2/HeroSection2/FasTrack2")
);
const Utilized2 = lazy(() => import("../../Component2/HeroSection2/Utilized2"));

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1800 });
  }, []);


  return (
    <main id="main">
      <FastTrack2 />
      <Choose2 />
      <Utilized2 />
      <Experience2 />
      <ClientContainer2/>
      <FooterCart2 />
      <TeamsContainer2 />
    </main>
  );
};

export default Home;
