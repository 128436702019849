import React from "react";
import icon1 from "../../Asset/Image/icon1.png";
import icon2 from "../../Asset/Image/icon2.png";
import icon3 from "../../Asset/Image/icon3.png";
import icon4 from "../../Asset/Image/icon4.png";
import icon5 from "../../Asset/Image/icon5.png";
import "../../Style/SliderCard.css";
const Test = () => {
  const data = [
    {
      icon: icon1,
      title: "Speed",
      desc: "Quickly materialize your ideas with lightning-fast execution.",
      animate: "fade-up",
    },
    {
      icon: icon2,
      title: "Quality",
      desc: "Craft top-notch products with unparalleled precision.",
      animate: "fade-up",
    },
    {
      icon: icon3,
      title: "Collaboration",
      desc: "Forge a seamless partnership for remarkable results.",
      animate: "fade-up",
    },
    {
      icon: icon4,
      title: "Cost-Effectiveness",
      desc: "Achieve more with cost-efficient solutions. ",
      animate: "fade-up",
    },
    {
      icon: icon5,
      title: "Flexibility",
      desc: "Adapt and evolve effortlessly with unparalleled flexibility.",
      animate: "fade-up",
    },
  ];

  return (
    <div className="pt-5 pb-5 chi">
      <div className="container">
        <div className="section-head col-sm-12 text-center" data-aos="fade-up">
          <h4 className="i2mvp">Why Choose Idea2MVP</h4>
        </div>
        <div className="row justify-content-center  row-gap-4">
          {data.map((item) => (
            <div className="col-lg-4 col-sm-6 gap-3" key={item.title}>
              <div
                className="item"
                data-aos={item?.animate}
                style={{ height: "100%" }}
              >
                <span className="take-icon feature_box_col-one">
                  <img src={item?.icon} alt="" />
                </span>
                <h6>{item?.title}</h6>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Test;
