import React from "react";
import { BlogContent } from "./BlogContent";
import { Link } from "react-router-dom";
const Blog = () => {
  return (
    <div>
      <div className="container">
        <h2 className="text-center mt-5 teamd">Blogs</h2>
        <div className="row">
          {BlogContent.map((item) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={item.id}>
                <Link
                  to={`/BlogDetails?id=${item.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="case-study-img">
                        <img
                          src={item.imgsrc}
                          alt="img"
                          className="img-responsive w-100"
                          style={{ height: "250px", objectFit: "cover" }}
                        />
                      </div>
                      <div className="case-study mt-3 ">
                        <h5>
                          {item.title.split(" ").slice(0, 10).join(" ")}
                          {item.title.split(" ").length > 5 ? "..." : ""}
                        </h5>
                      </div>
                      <div className="case-study-content mt-3">
                        <p>
                          {item.content.split(" ").slice(0, 25).join(" ")}
                          {item.content.split(" ").length > 25 ? "..." : ""}
                        </p>
                      </div>

                      <button className="btn btn-light">Read More..</button>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Blog;
