import React from "react";
import com from "../../Asset/Image/com.png";
import ohm from "../../Asset/Image/ohm.png";
import it from "../../Asset/Image/IT.png";
// Changing Image of IT.
import "../../../src/index.css";
const FooterCart2 = () => {
  const data = [com, ohm, it];

  return (
    <section className="foot-img2">
      <div className="container foot-img">
        <p className="fook" data-aos="fade-up">
          <span> Our startups & clients have garnered support from</span>
          <span className="d-block fook">
            some of the {"  "}
            <span className="text-black">
              most accomplished investors in the industry.
            </span>
          </span>
        </p>
        <div className="row py-4 gy-4 px-2">
          <div className="col-6 col-md-3 col-lg-3">
            <div className="bg-white h-100 e2k west" data-aos="fade-up">
              <div>
                <p className="mt-3">Westbridge</p>
                <p>Capital</p>
              </div>
            </div>
          </div>
          {data.map((item) => (
            <div className="col-6 col-md-3 col-lg-3">
              <div className="bg-white h-100 e2k" data-aos="fade-up">
                <img src={item} alt="" className="img-fluid p-3" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FooterCart2;
