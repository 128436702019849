import React from "react";
import { useLocation } from "react-router-dom";
import { DetailCaseStudyContent } from "./CaseStudyContent";
import { Justify } from "react-bootstrap-icons";

const CaseStudyDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const blogItem = DetailCaseStudyContent[`${id}`];
  return (
    <>
      <div className="container mt-5">
        <div className="title text-center" style={{ marginTop: "6rem" }}>
          <h1>{blogItem.title}</h1>
        </div>
        <div className="card mt-5" style={{ width: "50%", margin: "0 auto" }}>
          <div className="p-1">
            <div className="">
              <img src={blogItem.imgsrc} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div
          className="detail-content d-flex justify-content-center"
          style={{ maxWidth: "100%" }}
        >
          <p
            align="justify"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: blogItem.content }}
          />
        </div>
      </div>
    </>
  );
};

export default CaseStudyDetails;
