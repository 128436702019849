import React, { useEffect, useState } from "react";
import Logo2 from "../../Asset/Image/logo2.png";
import { Link } from "react-router-dom";
import "../../Style/Navbar.css";
import MobileMenu from "./MobileMenu/MobileMenu";
const menu = [
  {
    title: "Home",
    id: 1,
    slug: "/#main",
  },
  {
    title: "Clients",
    id: 2,
    slug: "/#client",
  },
  {
    title: "Team",
    id: 3,
    slug: "/#team",
  },
  {
    title: "Case Studies",
    id: 4,
    slug: "/casestudy",
  },

  {
    title: "Blog's",
    id: 5,
    slug: "/blog",
  },
];

const Navbar = () => {
  const handleButtonClick = () => {
    const whatsapp = "https://wa.link/4u9ce5";
    window.open(whatsapp, "_target");
  };
  const [activeItems, setActiveItems] = useState("Home");

  const handleItemClick = (item) => {
    setActiveItems(item);
  };

  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  return (
    <header className={color ? "header header-bg" : "header"}>
      <div className="navbar-desktop container d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Link to="/#main" className="logo">
            <img src={Logo2} className="img-fluid" alt="Idea2MVP" />
          </Link>
          <div className="main_menu d-flex align-items-center justify-content-between">
            {menu.map((item) => (
              <div key={item.id} className="main_menu_child">
                {item.slug[1] === "#" ? (
                  <a
                    href={`${item.slug}`}
                    onClick={() => {
                      handleItemClick(item.title);
                    }}
                    className={activeItems === item.title ? "active" : ""}
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to={`${item.slug}`}
                    onClick={() => {
                      handleItemClick(item.title);
                    }}
                    className={activeItems === item.title ? "active" : ""}
                  >
                    {item.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
        <button onClick={handleButtonClick} className="btn-nav take">
          Contact Us
        </button>
      </div>
      <MobileMenu menu={menu} />
    </header>
  );
};

export default Navbar;
