import React from "react";
import Physic from "../../Asset/Image/physic.png";
import lenden from "../../Asset/Image/lenden.png";
import drivezy from "../../Asset/Image/drivezy.png";
import piggy from "../../Asset/Image/piggy.png";
import "../../../src/index.css";
import Card from "react-bootstrap/Card";
const ClientContainer2 = () => {
  return (
    <section id="client">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-sm-6 col-md-12 col-lg-6" data-aos="fade-up">
            <p className="text-white clean text-md-left">
              Some of the best companies trust us
            </p>
          </div>
          <div className="col-sm-6 col-md-12 col-lg-6">
            <Card className="p-4 minhih tab">
              <div className="d-flex align-items-center justify-content-center phus">
                <Card.Img
                  vartant="top"
                  src={Physic}
                  className="img-fluid kin px-3"
                />
                <Card.Img
                  vartant="top"
                  src={Physic}
                  className="img-fluid kup"
                />
                <Card.Text className="phw">
                  Physics Wallah is India's top online ed-tech platform. And it
                  became a unicorn in June 2022.
                </Card.Text>
              </div>
            </Card>
          </div>
          <div className="col-sm-6 col-lg-4 text-center mt-3 mt-lg-5">
            <Card className="topp p-3 minhih" data-aos="fade-right">
              <Card.Img
                variant="top"
                src={lenden}
                className="img-fluid win1 kuin pt-1
              "
              />
              <Card.Body>
                <Card.Text className="com-p">
                  LenDen Club is India's largest alternate investment platform
                  which started operations in India in 2015.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-lg-4 text-center mt-3 mt-lg-5">
            <Card className="topp p-3 minhih" data-aos="fade-up">
              <Card.Img
                variant="top"
                src={drivezy}
                className="img-fluid win1 kuin"
              />
              <Card.Body>
                <Card.Text className="com-p">
                  Drivezy is the most affordable brand that provides self drive
                  cars and self drive bikes for rent across India.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-lg-4 col-md-12 text-center mt-3 mt-lg-5">
            <Card className="topp p-3 minhih" data-aos="fade-left">
              <Card.Img
                variant="top"
                src={piggy}
                className="img-fluid win1 kuin"
              />
              <Card.Body>
                <Card.Text className="com-p cop-m">
                  Piggy customizes a wealth plan just for you & automatically
                  invests your money in proven, tested portfolios.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientContainer2;
