import React, { useState } from "react";
import logo2 from "../../Asset/Image/logo2.png";
import "../../Style/SliderCard.css";
const menu = [
  {
    title: "Home",
    id: 1,
    slug: "/#main",
  },
  {
    title: "Clients",
    id: 2,
    slug: "/#client",
  },
  {
    title: "Team",
    id: 3,
    slug: "/#team",
  },
  {
    title: "Case Studies",
    id: 3,
    slug: "/casestudy",
  },
];
const Footer2 = () => {
  const [activeItems, setActiveItems] = useState("Home");
  const handleItemClick = (item) => {
    setActiveItems(item);
  };
  const handleFace = () => {
    const face = "https://www.facebook.com/profile.php?id=100085602987006";
    window.open(face, "_blank");
  };
  const handleButtonClick = () => {
    const whatsapp = "https://wa.link/4u9ce5";
    window.open(whatsapp, "_target");
  };
  const handleLine = () => {
    const line = "https://www.linkedin.com/company/89523736/admin/feed/posts/";
    window.open(line, "_blank");
  };
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8">
            <div>
              <img src={logo2} className="img-fluid footer-image" alt="" />
            </div>
            <div className="footer-paragraph pt-4">
              <p>
                Transforming Visionary Ideas into Reality:
                <span className="d-block">
                  Empowering Innovators with Expert Solutions
                </span>
              </p>
            </div>
            <div className="copyright">
              <p>
                © {new Date().getFullYear()}, MVPRockets. All rights reserved.
              </p>
            </div>
          </div>
          <div className="col-12 co-md-4 col-lg-4">
            <div className="row acc">
              <div className="second-child col-6 col-md-6 col-lg-6">
                <p>Navigation</p>
                <div className="cnd">
                  {menu.map((item) => {
                    return (
                      <div key={item.id} className="mb-2">
                        <a
                          href={`${item.slug}`}
                          onClick={() => handleItemClick(item.title)}
                          className={activeItems === item.title ? "active" : ""}
                        >
                          {item.title}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="second-child2 col-6 col-md-6 col-lg-6">
                <p>Socials</p>
                <div className="link_icon ">
                  <i
                    className="bi bi-facebook fs-3"
                    onClick={() => handleFace()}
                  ></i>
                  <i
                    className="bi bi-whatsapp px-2 fs-3"
                    onClick={handleButtonClick}
                  ></i>
                  <i
                    className="bi bi-linkedin fs-4"
                    onClick={() => handleLine()}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center py-3 pt-5 dek">
          © {new Date().getFullYear()}, MVPRockets. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer2;
