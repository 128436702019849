import React from "react";
import Card from "react-bootstrap/Card";
import "../../Style/HeroSection.css";
const Choose2 = () => {
  return (
    <section className="mt-5 abs1">
      <div className="container">
        <div className="row px-3">
          <div className="col-12 col-md-4 col-lg-4 justify-content-center d-flex menu">
            <Card data-aos="fade-up" className="w-100">
              <Card.Body className="d-flex align-items-center justify-content-evenly mt-2">
                <p className="fs-1 plus">10+</p>
                <p className="spanking">
                  MVPs launched by the
                  <span className="d-block">leadership team</span>
                </p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-4 col-lg-4 justify-content-center d-flex menu">
            <Card data-aos="fade-up" className="w-100">
              <Card.Body className="d-flex align-items-center justify-content-evenly mt-2">
                <p className="fs-1 plus">40+</p>
                <p className="spanking">
                  Combined years of
                  <span className="d-block">experience in Startups</span>
                </p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-4 col-lg-4 justify-content-center d-flex menu">
            <Card data-aos="fade-up" className="w-100">
              <Card.Body className="d-flex align-items-center justify-content-evenly mt-2">
                <p className="fs-1 plus">60+</p>
                <p className="spanking">
                  Combined years of
                  <span className="d-block">Product Building</span>
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Choose2;
