import React from "react";
import { useLocation } from "react-router-dom";
import { DetailCaseStudyContent } from "./BlogContent";
import "./BlogDetails.css";

const BlogDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const blogItem = DetailCaseStudyContent[`${id}`];
  return (
    <>
      <div className="container mt-5">
        <div className="title text-center" style={{ marginTop: "6rem" }}>
          <h1>{blogItem.title}</h1>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <div className="detail-content d-flex justify-content-center">
            <p
              align="justify"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: blogItem.content }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
