import React from "react";
import Card from "react-bootstrap/Card";
import rajesh from "../../Asset/Image/rajesh.jpg";
import kunal from "../../Asset/Image/kunal.png";
import pulkit from "../../Asset/Image/pulkit.png";
import anup from "../../Asset/Image/anup.png";
import sachin from "../../Asset/Image/sachin.jpg";
import { AiFillLinkedin } from "react-icons/ai";
import "../../../src/index.css";
const TeamsContainer2 = () => {
  const team = [
    {
      img: rajesh,
      name: "Er. Rajesh Kumar Sah",
      position: "CEO / CO-Founder",
      work: "Ex-Avail-Finance · Urbanladder · Samsung",
      linkedin: "https://www.linkedin.com/in/rajesh-sah-807b4326/",
      animate: "fade-up",
    },
    {
      img: sachin,
      name: "Sachin Sabbarwal",
      position: "Engineering Manager at ML Experts",
      work: "Ex-Senior Software Engineer at Terracotta · Experience: 11+ years",
      linkedin: "https://www.linkedin.com/in/sachin-sabbarwal-7a90aa28/",
      animate: "fade-up",
    },
    {
      img: kunal,
      name: "Kunal Sangwan",
      position: "Mentor",
      work: "CTO / Co-Founder at Piggy (YC-S17) Ex-Amazon",
      linkedin: "https://www.linkedin.com/in/nuhbye/",
      animate: "fade-up",
    },
    {
      img: pulkit,
      name: "Pulkit Agrawal",
      position: "Mentor",
      work: "Senior Software Engineer at GOOGLE Co-Founder at Acredit Technology",
      linkedin: "https://www.linkedin.com/in/pulkit-agrawal-1b73224/",
      animate: "fade-up",
    },
    {
      img: anup,
      name: "Anup Singh",
      position: "Software Engineer",
      work: "Ex-Ola-Finance · Khalti Full-Stack Developer",
      linkedin: "https://www.linkedin.com/in/anup-singh-a849aa146/",
      animate: "fade-up",
    },
  ];

  return (
    <section id="team">
      <div className="container">
        <h2 className="text-center teamd" data-aos="fade-up">
          Our Team
        </h2>
        <div className="row kts">
          {team.map((item) => (
            <div className="col-lg-4 col-sm-6 mt-4" key={item.name}>
              <Card className="h-100" data-aos={item.animate}>
                <Card.Img
                  variant="top"
                  src={item.img}
                  className="p-4 card-photo"
                />
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <h4>{item.name}</h4>
                    <a
                      href={item.linkedin}
                      className=" pre-auto"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillLinkedin size={30} style={{ color: "#0077B5" }} />
                    </a>
                  </div>
                  <div>
                    <p className="kts-p">{item.position}</p>
                    <p className="kts-p1">{item.work}</p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamsContainer2;
